import React, { useEffect, useRef } from 'react';


export default function ConfettiModal() {
  const confettiContainerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const confettiContainer = confettiContainerRef.current;
    if (!confettiContainer) return;

    const colors = ['bg-teal-300', 'bg-teal-500', 'bg-teal-700', 'bg-amber-500', 'bg-red-600', 'bg-rose-200'];
    let confettiCount = 0;
    let isMounted = true;

    function createConfettiPiece() {
      if (confettiCount < 400 && isMounted) {
        const confettiPiece = document.createElement('div');
        confettiPiece.className = `confetti-piece absolute w-2.5 h-1 ${colors[Math.floor(Math.random() * colors.length)]} animate-burst`;

        confettiPiece.style.left = `${Math.random() * 100}vw`;
        confettiPiece.style.top = `${-Math.random() * 800}px`;
        confettiPiece.style.setProperty('--rotation', Math.random().toString());


        confettiPiece.addEventListener('animationend', () => {
          confettiContainer.removeChild(confettiPiece);
          confettiContainer.className = 'hidden'
        });

        confettiContainer.appendChild(confettiPiece);
        confettiCount++;
      }
    }

    function createConfetti() {
      for (let i = 0; i < 400; i++) {
        createConfettiPiece();
      }
    }

    createConfetti();
  }, []);

  return (
    <div
      ref={confettiContainerRef}
      className="confetti-container fixed inset-0 w-full h-full overflow-hidden">
    </div>
  );
}