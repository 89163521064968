import React, { createContext, ReactNode, useCallback, useContext, useState } from 'react';

type EventCallback = (data: any) => void;
type Events = { [key: string]: EventCallback[] };

interface EventBusContextProps {
  emit: (name: string, data: any) => void;
  on: (name: string, cb: EventCallback) => () => void;
}

export const EventBusContext = createContext<EventBusContextProps | undefined>(undefined);

interface EventBusProviderProps {
  children: ReactNode;
}

/**
 * Custom hook for accessing the EventBus functionality.
 * @returns {EventBusContextProps} Object containing emit and on methods for event handling
 * @throws {Error} If used outside of EventBusProvider
 * @example
 * const { emit, on } = useEventBus();
 *
 * // Subscribe to an event
 * useEffect(() => {
 *   const cleanup = on('eventName', (data) => {
 *     console.log(data);
 *   });
 *   return cleanup;
 * }, []);
 *
 * // Emit an event
 * emit('eventName', { someData: 'value' });
 */

export const EventBusProvider: React.FC<EventBusProviderProps> = ({ children }) => {
  const [events, setEvents] = useState<Events>({});

  const emit = useCallback(
    (name: string, data: any) => {
      if (events[name]) {
        for (const callback of events[name]) {
          callback(data);
        }
      }
    },
    [events]
  );

  const on = useCallback((name: string, cb: EventCallback) => {
    setEvents((prevEvents) => {
      const newEvents = { ...prevEvents };
      if (!newEvents[name]) {
        newEvents[name] = [];
      }
      newEvents[name].push(cb);
      return newEvents;
    });

    return () => {
      setEvents((prevEvents) => {
        const newEvents = { ...prevEvents };
        newEvents[name] = newEvents[name].filter((callback) => callback !== cb);
        return newEvents;
      });
    };
  }, []);

  return <EventBusContext.Provider value={{ emit, on }}>{children}</EventBusContext.Provider>;
};

export const useEventBus = (): EventBusContextProps => {
  const context = useContext(EventBusContext);
  if (!context) {
    throw new Error('useEventBus must be used within an EventBusProvider');
  }
  return context;
};
