import React from 'react';
import { RawTutorialStep } from '../../types';

export const extendAccess: RawTutorialStep[] = [
  {
    id: 'extend-access-page',
    route: '/cart/create',
    content: {
      text: [
        <span className="font-semibold ">
          Tu przedłużysz swój miesięczny lub roczny dostęp do aplikacji Intendent Pro, klikając w przycisk "Wybierz".
        </span>,
      ],
    },
  },
  {
    route: '/cart/create',
    content: {
      title: 'Rodzaje abonamentów',
      text: ['Poznaj różnicę między wersją standardową, a wersją profesjonalną.'],
    },
    target: {
      selector: '#subscription-details',
      padding: 3,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
  },
  {
    route: '/cart/create',
    content: {
      text: [<span className="font-semibold text-primary-500">Kliknij w przycisk „Ustawienia”</span>],
    },
    target: {
      selector: '#settings-link',
      padding: 3,
    },
    canGoNext: false,
  },
];
