import { CLOSE_MODAL_LOADER, OPEN_MODAL_LOADER } from '@/redux/actions/loaderModalActions';

const initialState = {
  isOpen: false,
  text: '',
};

const loaderModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_LOADER:
      return { ...state, isOpen: true, text: action.payload.text };
    case CLOSE_MODAL_LOADER:
      return { ...state, isOpen: false };
    default:
      return state;
  }
};

export default loaderModalReducer;
