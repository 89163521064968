export function matchUrlWithRoute(url: string, routes: string[]) {
  return routes.some((route) => {
    const urlSegments = url.split('/');
    const routeSegments = route.split('/');

    if (urlSegments.length !== routeSegments.length) {
      return false;
    }

    for (let i = 0; i < urlSegments.length; i++) {
      const urlSegment = urlSegments[i];
      const routeSegment = routeSegments[i];

      if (routeSegment === '{*}') {
        continue;
      }

      if (urlSegment !== routeSegment) {
        return false;
      }
    }

    return true;
  });
}

export function routeContainsPlaceholder(routes: string[]) {
  return routes.some((route) => route.includes('{*}'));
}

export function hasRouteOverlap(routesA?: string[], routesB?: string[]) {
  if (!routesA || !routesB) return false;
  return routesA.some((routeA) => routesB.some((routeB) => routeA === routeB));
}
