import React, { createContext, useState, useContext, ReactNode, FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '@/redux/slices';
import { setCurrentStepId, setTutorialActive, setTutorialStatus } from '@/redux/slices/tutorialSlice';
import { ErrorStep, Tutorial, TutorialStep, TutorialStepConfig } from '../types';
import { GET_STARTED_TUTORIAL_NAME, tutorials } from '../Steps';
import { Inertia } from '@inertiajs/inertia';
import { usePage } from '@inertiajs/inertia-react';
import { hasRouteOverlap, matchUrlWithRoute, routeContainsPlaceholder } from '../utils/routeUtils';
import { handleTutorialMenuCreation } from '../utils/menuCreator';
import { createElementObserver } from '../utils/elementObserver';
import { navigateToCurrentStep } from '../utils/stepNavigation';
import { useEventBus } from '@/Hooks/useEventBus';

interface TutorialContextValue {
  isLastStep: boolean;
  tutorial: Tutorial | null;
  currentStep: TutorialStep | null;
  isTutorialActive: boolean;
  targetElement: HTMLElement | null;
  setTargetElement: (element: HTMLElement | null) => void;
  startTutorial: () => void;
  startFromStep: (stepId: string) => void;
  setCurrentStepById: (stepId: string) => void;
  assignTutorial: (tutorialName?: string) => void;
  nextStep: () => void;
  previousStep: () => void;
  completeTutorial: () => void;
  endTutorial: () => void;
  closeTutorial: () => void;
  canProceedToNextStep?: boolean;
  canProceedToPreviousStep?: boolean;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  isDropdownLocked: boolean;
  setIsDropdownLocked: (value: boolean) => void;
  setDefaultStepForIds: (defaultId: string, stepsToReplace: string[] | string) => void;
  removeDefaultStepForIds: (stepsToRemove: string[] | string) => void;
  createTutorialMenu: (institutionId: number) => void;
  handleFormErrorNavigation: (
    errors: Record<string, string>,
    fieldsMap: Record<string, TutorialStepConfig>,
    saveStepId: string
  ) => void;
}
const TutorialContext = createContext<TutorialContextValue | undefined>(undefined);

export const TutorialProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const dispatch = useDispatch();
  const {
    url,
    props: { config },
  } = usePage();

  const { currentStepId, isTutorialActive, isTutorialEnabled } = useSelector((state: RootState) => state.tutorial);

  const [tutorial, setTutorial] = useState<Tutorial | null>(() => {
    const tutorial = tutorials.find((t) => t.name === GET_STARTED_TUTORIAL_NAME);
    return tutorial as Tutorial;
  });
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDropdownLocked, setIsDropdownLocked] = useState(false);
  const [defaultStepsMap, setDefaultStepsMap] = useState<Record<string, string>>({});
  const [errorStepsSequence, setErrorStepsSequence] = useState<ErrorStep[]>([]);
  const [isErrorNavigation, setIsErrorNavigation] = useState(false);

  const { on } = useEventBus();

  const { isTutorialEnabled: configTutorialEnabled } = config as {
    isTutorialEnabled: boolean;
  };
  const stepsMap = useMemo(() => {
    const map = new Map<string, TutorialStep>();
    if (!tutorial?.steps) return map;

    tutorial.steps.forEach((step) => {
      if (!step.id) {
        console.warn(`Tutorial "${tutorial.name}" has a step without an 'id'. Step content: "${step.content?.text}"`);
        return;
      }
      map.set(step.id, step);
    });

    return map;
  }, [tutorial]);

  const currentStep = useMemo(() => {
    return currentStepId ? stepsMap.get(currentStepId) || null : null;
  }, [currentStepId, stepsMap]);

  useEffect(() => {
    dispatch(setTutorialStatus(!!configTutorialEnabled));
  }, [configTutorialEnabled]);

  useEffect(() => {
    if (!isTutorialEnabled || !tutorial?.steps) return;

    const firstStep = tutorial.steps.find((step) => matchUrlWithRoute(url, step.route));
    if (!firstStep) return;

    if (!isTutorialActive) {
      const defaultStepId = defaultStepsMap[firstStep.id];
      const stepToUse = defaultStepId || firstStep.id;
      setCurrentStepById(stepToUse);
      return;
    }

    const prevStep = stepsMap.get(currentStep?.previous || '')?.route;
    if (!prevStep && !hasRouteOverlap(stepsMap.get(firstStep.id)?.route, currentStep?.route)) {
      setCurrentStepById(firstStep.id);
    }
  }, [url, tutorial, isTutorialEnabled, isTutorialActive]);

  useEffect(() => {
    if (!isTutorialActive || !currentStepId) return;
    if (!defaultStepsMap[currentStepId]) return;

    const defaultStepId = defaultStepsMap[currentStepId];
    const stepToUse = defaultStepId || currentStepId;

    if (stepToUse === currentStepId) return;

    setCurrentStepById(stepToUse);
  }, [currentStepId, defaultStepsMap]);

  useEffect(() => {
    if (!isTutorialActive) return;

    let observer: MutationObserver | null = null;

    const checkElementExists = () => {
      if (!currentStep?.target?.selector) {
        if (targetElement) {
          setTargetElement(null);
        }
        return;
      }
      const element = document.querySelector(currentStep.target.selector) as HTMLElement;
      if (element) {
        setTargetElement(element);
        return;
      }

      setTargetElement(null);
      observer = createElementObserver(currentStep.target.selector, setTargetElement);
    };

    checkElementExists();
    return () => observer?.disconnect();
  }, [currentStepId, url, isDropdownLocked, isTutorialActive, targetElement]);

  useEffect(() => {
    if (!currentStep?.route || !isTutorialActive || !isTutorialEnabled) {
      return;
    }

    const currentUrl = new URL(url, window.location.origin);
    const isOnCorrectRoute = matchUrlWithRoute(currentUrl.pathname, currentStep.route);

    if (!isOnCorrectRoute) {
      handleRouteNavigation();
    }
  }, [currentStep, url, isTutorialActive]);
  useEffect(() => {
    if (!currentStep?.onMount) return;

    const cleanup = currentStep.onMount({
      targetElement,
      nextStep,
      previousStep,
      setCurrentStepById,
      stopLoading,
      startLoading,
      lockDropdown,
      unlockDropdown,
    });

    return cleanup;
  }, [currentStepId, targetElement]);

  useEffect(() => {
    return on('tutorial:update-step', (data) => {
      if (currentStep?.id === data.fromStepId) {
        setCurrentStepById(data.toStepId);
      }
    });
  }, [currentStep]);

  useEffect(() => {
    if (!currentStep?.target?.selector || !isTutorialActive) {
      stopLoading();
      return;
    }

    setIsLoading(!targetElement);
  }, [currentStepId, targetElement]);

  const handleRouteNavigation = () => {
    if (currentStep?.next) {
      const nextTutorialStep = stepsMap.get(currentStep.next);
      if (nextTutorialStep?.route && matchUrlWithRoute(url, nextTutorialStep.route)) {
        nextStep(true);
        return;
      }
    }

    if (currentStep?.previous) {
      const prevStep = stepsMap.get(currentStep.previous);
      if (prevStep?.route && matchUrlWithRoute(url, prevStep.route)) {
        previousStep(true);
        return;
      }
    }
    navigateToCurrentStep(currentStep, tutorial, setCurrentStepById);
  };
  const stopLoading = () => {
    setIsLoading(false);
  };
  const startLoading = () => {
    setIsLoading(true);
  };

  const lockDropdown = () => {
    setIsDropdownLocked(true);
  };
  const unlockDropdown = () => {
    setIsDropdownLocked(false);
  };

  const startTutorial = () => {
    if (!isTutorialEnabled) return;
    if (!tutorial?.steps.length) return;

    if (currentStepId && stepsMap.has(currentStepId)) {
      dispatch(setTutorialActive(true));
      return;
    }

    const prioritizedFirstStep = tutorial.steps.find((step) => step.showAsFirstStep === true);

    if (prioritizedFirstStep) {
      dispatch(setCurrentStepId(prioritizedFirstStep.id));
    } else {
      dispatch(setCurrentStepId(tutorial.steps[0]?.id || null));
    }
    dispatch(setTutorialActive(true));
  };

  const nextStep = (skipValidation = false) => {
    if (!currentStep?.next) {
      endTutorial();
      return;
    }

    if (!skipValidation && currentStep.validationCallback) {
      const { isValid } = currentStep.validationCallback(targetElement);
      if (!isValid) return;
    }

    if (currentStep.onNext) {
      currentStep.onNext({ targetElement });
    }
    if (isErrorNavigation) {
      const currentIndex = errorStepsSequence.findIndex((step) => step.id === currentStepId);
      setCurrentStepById(errorStepsSequence[currentIndex + 1].id);
      return;
    }

    if (currentStep.next && stepsMap.has(currentStep.next)) {
      const nextStepId = defaultStepsMap[currentStep.next] || currentStep.next;
      const nextStep = stepsMap.get(nextStepId);

      if (!nextStep) return;
      setTargetElement(null);
      dispatch(setCurrentStepId(currentStep.next));
    }
  };

  const previousStep = (skipValidation = false) => {
    if (!currentStep?.previous) return;

    if (currentStep.onPrevious) {
      currentStep.onPrevious({ targetElement });
    }

    if (isErrorNavigation) {
      const currentIndex = errorStepsSequence.findIndex((errorStep) => currentStepId === errorStep.id);
      if (currentIndex >= 0) {
        let targetIndex = currentIndex - 1;
        while (targetIndex >= 0 && errorStepsSequence[targetIndex].isSubStep) {
          targetIndex--;
        }
        if (targetIndex >= 0) {
          setCurrentStepById(errorStepsSequence[targetIndex].id);
        }
      }
      return;
    }

    const prevStepId = defaultStepsMap[currentStep.previous] || currentStep.previous;
    const prevStep = stepsMap.get(prevStepId);

    if (!prevStep) return;

    if (!skipValidation && currentStep.validationCallback) {
      const { isValid } = currentStep.validationCallback(targetElement);
      if (!isValid) return;
    }

    if (prevStep.route && !matchUrlWithRoute(url, prevStep.route)) {
      setIsLoading(true);
      setTargetElement(null);

      Inertia.visit(prevStep.route[0], {
        onSuccess: () => {
          dispatch(setCurrentStepId(prevStepId));
        },
      });
      return;
    }
    dispatch(setCurrentStepId(prevStepId));
  };

  const setCurrentStepById = (stepId: string) => {
    if (!isTutorialEnabled || !stepsMap.has(stepId)) return;
    dispatch(setCurrentStepId(stepId));
  };

  const handleFormErrorNavigation = (
    errors: Record<string, string>,
    fieldsMap: Record<string, TutorialStepConfig>,
    saveStepId: string
  ) => {
    if (!isTutorialActive || !isTutorialEnabled) return;
    const errorFields = Object.keys(errors);

    if (errorFields.length === 0) {
      setErrorStepsSequence([]);
      setIsErrorNavigation(false);
      setCurrentStepById(saveStepId);
      return;
    }

    const errorSteps = errorFields.reduce((steps: ErrorStep[], field) => {
      const fieldConfig = fieldsMap[field];
      if (!fieldConfig) return steps;

      steps.push({ id: fieldConfig.stepId });

      if (fieldConfig.subSteps) {
        fieldConfig.subSteps.forEach(({ stepId, isSubStep }) => {
          if (stepId) {
            steps.push({ id: stepId, isSubStep });
          }
        });
      }

      return [...steps];
    }, []);

    setErrorStepsSequence([...errorSteps, { id: saveStepId }]);
    setIsErrorNavigation(true);

    if (errorSteps.length > 0) {
      const isCurrentStepInErrorSteps = errorSteps.some((step) => step.id === currentStepId);

      if (!isCurrentStepInErrorSteps) {
        setCurrentStepById(errorSteps[0].id);
      }
    }
  };
  const setDefaultStepForIds = (defaultId: string, stepsToReplace: string[] | string) => {
    const stepsArray = Array.isArray(stepsToReplace) ? stepsToReplace : [stepsToReplace];

    const newDefaultSteps = stepsArray.reduce(
      (acc, stepId) => ({
        ...acc,
        [stepId]: defaultId,
      }),
      {}
    );

    setDefaultStepsMap((prev) => ({
      ...prev,
      ...newDefaultSteps,
    }));
  };
  const removeDefaultStepForIds = (stepsToRemove: string[] | string) => {
    const stepsArray = Array.isArray(stepsToRemove) ? stepsToRemove : [stepsToRemove];

    setDefaultStepsMap((prev) => {
      const newMap = { ...prev };
      stepsArray.forEach((stepId) => delete newMap[stepId]);
      return newMap;
    });
  };

  const assignTutorial = (tutorialName = GET_STARTED_TUTORIAL_NAME) => {
    if (tutorial?.name === GET_STARTED_TUTORIAL_NAME) return;

    const newTutorial = tutorials.find((t) => t.name === tutorialName);
    if (!newTutorial) return;
  };
  const endTutorial = () => {
    dispatch(setCurrentStepId(null));
    dispatch(setTutorialActive(false));
    setTargetElement(null);
  };

  const completeTutorial = () => {
    endTutorial();
  };

  const createTutorialMenu = (institutionId: number) => {
    handleTutorialMenuCreation(dispatch, institutionId);
  };

  const isLastStep = !currentStep?.next;

  const canProceedToNextStep = useMemo(() => {
    if (!currentStep?.next) return;
    const nextStep = stepsMap.get(currentStep.next);
    if (!nextStep || !currentStep.canGoNext) return;

    if (currentStep.validationCallback && targetElement) {
      const { isValid } = currentStep.validationCallback(targetElement);
      return isValid;
    }

    return true;
  }, [currentStep, stepsMap, targetElement]);

  const canProceedToPreviousStep = useMemo(() => {
    if (!currentStep?.previous) return;
    const prevStep = stepsMap.get(currentStep.previous);
    if (!prevStep || !currentStep.canGoPrevious) return;

    if (routeContainsPlaceholder(prevStep.route) && !hasRouteOverlap(prevStep.route, currentStep.route)) return false;

    if (prevStep.validationCallback && targetElement) {
      const { isValid } = prevStep.validationCallback(targetElement);
      return isValid;
    }

    return true;
  }, [currentStep, stepsMap, targetElement]);

  const value: TutorialContextValue = {
    isLastStep,
    tutorial,
    currentStep,
    isTutorialActive,
    targetElement,
    setTargetElement,
    startTutorial,
    startFromStep: (stepId: string) => {
      if (stepsMap.has(stepId)) {
        dispatch(setCurrentStepId(stepId));
        dispatch(setTutorialActive(true));
      }
    },
    setCurrentStepById,
    assignTutorial,
    nextStep,
    previousStep,
    completeTutorial,
    endTutorial,
    closeTutorial: () => dispatch(setTutorialActive(false)),
    handleFormErrorNavigation,
    canProceedToNextStep,
    canProceedToPreviousStep,
    isLoading,
    setIsLoading,
    isDropdownLocked,
    setIsDropdownLocked,
    setDefaultStepForIds,
    removeDefaultStepForIds,
    createTutorialMenu,
  };

  return <TutorialContext.Provider value={value}>{children}</TutorialContext.Provider>;
};

export const useTutorial = (): TutorialContextValue => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error('useTutorial must be used within TutorialProvider');
  }
  return context;
};
