import React from 'react';
import { RawTutorialStep } from '../../types';
import {
  subscribeToClick,
  subscribeToDisableOnHover,
  subscribeToModalList,
  subscribeToNextStep,
} from '../../utils/subscribeToClick';

export const menuPreviewPage: RawTutorialStep[] = [
  {
    id: 'menu-preview-page',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Sprawdź urozmaicenie jadłospisu',
      text: ['Podgląd całego menu pozwala dostrzec powtarzające się potrawy.'],
    },
    target: {
      selector: '#data-preview-list',
      padding: { top: 0, right: 5, bottom: 0, left: 5 },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();
      return () => null;
    },
  },
  {
    id: 'menu-preview-page-diet-buttons-not-a-legend',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Jadłospisy dla diet',
      text: [
        'Poza dietą podstawową możesz planować również diety: ',
        'bezmleczną, bezjajeczną, bezglutenową, wegetariańską i eliminacyjną.',
      ],
    },
    target: {
      selector: '#diets-buttons',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
  },
  {
    id: 'diet-button-dairyFree',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      text: [<span className="font-semibold text-primary-500">Kliknij w przycisk „Dieta bezmleczna”</span>],
    },
    target: {
      selector: '#diet-button-dairyFree',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, nextStep }) => {
      return subscribeToNextStep({ targetElement, nextStep });
    },
    onNext: ({ targetElement }) => {
      if (!targetElement) return;
      targetElement.click();
    },
    canGoNext: false,
  },
  {
    id: 'menu-preview-page-diet-buttons',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Legenda',
      text: [
        <span>
          <b className="font-semibold bg-red-500/40">Potrawy do wymiany</b>: to potrawy zawierające w składzie alergen.
          Należy je wymienić klikając w potrawę podświetloną na kolor czerwony.
        </span>,
        <span>
          <b className="font-semibold bg-green-500/40">Potrawy wymienione</b>: to potrawy, które zostały przez Ciebie
          oznaczone jako alternatywy dla diet.
        </span>,
      ],
    },
    target: {
      selector: '#menu-diet-buttons',
      padding: 5,
    },
  },

  {
    id: 'days-preview-product-meal-items',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Modyfikacja jadłospisów dla diet bez bilansowania',
      text: [
        'Aby wymienić potrawę na taką, która nie zawiera alergenu i spełnia kryteria diety,',
        <span>&nbsp;</span>,
        <span className=" text-primary-500">
          kliknij w potrawę podświetloną na <span className="font-semibold text-red-500/90">czerwono</span>.
        </span>,
      ],
    },
    target: {
      selector: '#days-preview-product-meal-items',
      padding: 0,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep }) => subscribeToNextStep({ targetElement, nextStep }),
    canGoNext: false,
  },
  {
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Wybierz inną potrawę dla diety',
      text: [<span className=" text-primary-500">Kliknij w przycisk „Wybierz potrawę”.</span>],
    },
    target: {
      selector: '#alternative-dish-modal',
      padding: { top: 50, bottom: 50, left: 24, right: 24 },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep, setCurrentStepById }) => {
      const closeButton = targetElement?.querySelector('button') as HTMLElement;
      const button = targetElement?.querySelector('#set-alternative-dish-modal-button') as HTMLElement;

      const closeSubscription = subscribeToClick({
        targetElement: closeButton,
        onClick: () => setCurrentStepById('days-preview-product-meal-items'),
      });

      const nextStepSubscription = subscribeToNextStep({
        targetElement: button,
        nextStep,
      });

      return () => {
        closeSubscription?.();
        nextStepSubscription?.();
      };
    },
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
    canGoNext: false,
  },
  {
    id: 'select-dish-modal',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Lista potraw',
      text: [
        <span>
          Wybierz z listy potrawę alternatywną dla diety bezmlecznej. Wybrana potrawa musi spełniać kryteria diety.
          Jeśli będzie zawierać niedozwolony alergen, program Cię o tym poinformuje.{' '}
          <span className=" text-primary-500">Dla Samouczka wybierz: </span>
        </span>,
        <span className=" text-primary-500">„Zupa pomidorowa bezmleczna”, klikając w „+”.</span>,
      ],
    },
    target: {
      selector: '#select-dish-modal',
      padding: { top: 20, bottom: 20, left: 24, right: 24 },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    includeBottomMargin: false,
    onMount: ({ targetElement, nextStep, setCurrentStepById }) =>
      subscribeToModalList({
        targetElement,
        nextStep,
        onButtonClick: () => setCurrentStepById('days-preview-product-meal-items'),
      }),
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('#alternative-product-continu-button') as HTMLElement;
      button?.click();
    },
    canGoNext: false,
    previous: 'days-preview-product-meal-items',
  },
  {
    id: 'default-alternative-modal',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Zapamiętaj lub wymień jednorazowo',
      text: [
        'Po wybraniu potrawy alternatywnej możesz zdecydować czy oznaczyć ją jako domyślny zamiennik w danej diecie. Program zapamięta Twój wybór i w kolejnym jadłospisie dla diety sam wykona zamianę. ',
        <span className=" text-primary-500">
          Jeśli wybierzesz przycisk "<span className="font-semibold ">Nie, przejdź dalej</span>" wymiana zajdzie tylko w
          obecnym jadłospisie.
        </span>,
      ],
    },
    target: {
      selector: '#default-alternative-modal',
      padding: { top: 20, bottom: 20, left: 24, right: 24 },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep, setCurrentStepById }) => {
      const closeButton = targetElement?.querySelector('button') as HTMLElement;
      const button = targetElement?.querySelector('#alternative-product-continu-button') as HTMLElement;
      const rememberButton = targetElement?.querySelector('#alternative-product-remember-button') as HTMLElement;

      const closeSubscription = subscribeToClick({
        targetElement: closeButton,
        onClick: () => setCurrentStepById('days-preview-product-meal-items'),
      });

      const nextStepSubscription = subscribeToNextStep({
        targetElement: button,
        nextStep,
      });
      const disabelSubscription = subscribeToDisableOnHover({ targetElement: rememberButton });

      return () => {
        closeSubscription?.();
        nextStepSubscription?.();
        disabelSubscription?.();
      };
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('#alternative-product-continu-button') as HTMLElement;
      button?.click();
    },
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
    previous: 'days-preview-product-meal-items',
    next: 'menu-preview-page-print-button',
  },

  {
    id: 'select-alternative-dish-modal',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Potrawa zawiera alergen',
      text: ['Wybierz inną potrawę.', <span className=" text-primary-500">Kliknij w przycisk "Cofnij". </span>],
    },
    target: {
      selector: '#select-alternative-dish-modal',
      padding: { top: 20, bottom: 20, left: 24, right: 24 },
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    includeBottomMargin: false,
    onMount: ({ targetElement, nextStep }) => {
      const revertButton = targetElement?.querySelector('#revert-button') as HTMLButtonElement;

      return subscribeToNextStep({ targetElement: revertButton, nextStep });
    },
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('#alternative-product-continu-button') as HTMLElement;
      button?.click();
    },
    canGoNext: false,
    previous: 'select-dish-modal',
    next: 'select-dish-modal',
  },
  {
    id: 'menu-preview-page-print-button--no-return-button',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Drukuj jadłospis',
      text: [
        'Przycisk „Drukuj” przeniesie Cię do okna z wydrukami.',
        'Po ułożeniu jadłospisu przetestuj wszystkie rodzaje wydruków.',
      ],
    },
    target: {
      selector: '#menu-preview-print-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
    previous: 'menu-preview-page',
    next: 'menu-preview-options',
  },

  {
    id: 'menu-preview-page-print-button',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Drukuj jadłospis',
      text: [
        'Przycisk „Drukuj” przeniesie Cię do okna z wydrukami.',
        'Po ułożeniu jadłospisu przetestuj wszystkie rodzaje wydruków.',
      ],
    },
    target: {
      selector: '#menu-preview-print-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
    previous: 'days-preview-product-meal-items',
  },
  {
    id: 'menu-preview-options',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Opcje jadłospisu',
      text: [<span className="text-primary-500">Kliknij w przycisk „Opcje”.</span>],
    },
    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      const unsubscribe = subscribeToNextStep({ targetElement, nextStep });
      unlockDropdown();
      return unsubscribe;
    },
    canGoNext: false,
  },
  {
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Edycja',
      text: ['Przejście do edycji jadłospisu.'],
    },
    target: {
      selector: '#menu-preview-dropdown-edit-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      lockDropdown();

      return unsubscribe;
    },
  },
  {
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Powiel',
      text: [
        'Powielanie jadłospisu to najczęściej wykorzystywana funkcja,',
        'by dostosować stary jadłospis do nowych parametrów.',
        'Powiel stary jadłospis, zmień daty na aktualne,',
        'jeśli chcesz nanieś drobne zmiany i nowy jadłospis gotowy!',
      ],
    },
    target: {
      selector: '#menu-preview-dropdown-duplicate-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      lockDropdown();

      return unsubscribe;
    },
  },
  {
    id: 'menu-preview-options-share',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Udostępnij',
      text: [
        'Klikając w przycisk "Udostępnij", wygenerujesz link do podglądu jadłospisu. Skopiuj link i wklej go np. na stronę internetową swojej placówki. Osoby niezalogowane będą mogły podejrzeć Twój jadłospis w trypie „tylko do podglądu”. ',
      ],
    },
    target: {
      selector: '#menu-details-share-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      lockDropdown();

      return unsubscribe;
    },
  },
  {
    id: 'menu-preview-page-recalculation-button',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Przelicz',
      text: [
        'Dostosowanie jadłospisu do norm innej placówki.',
        'Program przeliczy gramatury wszystkich potraw, aby dopasować je do nowych parametrów.',
        'Przekształci jadłospis przedszkolny na szkolny lub przedszkolny na żłobkowy.',
        'Korzystając z funkcji przelicz, nie musisz układać drugiego,',
        'podobnego jadłospisu od początku. Program przygotuje go za Ciebie!',
      ],
    },
    target: {
      selector: '#menu-list-recalculation-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      lockDropdown();

      return unsubscribe;
    },
    next: 'menu-preview-dropdown-balance-button',
  },
  {
    id: 'menu-preview-dropdown-recalculation-button--no-balance-button',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Przelicz',
      text: [
        'Dostosowanie jadłospisu do norm innej placówki.',
        'Program przeliczy gramatury wszystkich potraw, aby dopasować je do nowych parametrów.',
        'Przekształci jadłospis przedszkolny na szkolny lub przedszkolny na żłobkowy.',
        'Korzystając z funkcji przelicz, nie musisz układać drugiego,',
        'podobnego jadłospisu od początku. Program przygotuje go za Ciebie!',
      ],
    },
    target: {
      selector: '#menu-list-recalculation-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      lockDropdown();

      return unsubscribe;
    },
    previous: 'menu-preview-options-share',
    next: 'menu-preview-dropdown-delete-button--no-balance-button',
  },
  {
    id: 'menu-preview-dropdown-balance-button',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Zbilansuj dietę',
      text: [
        'Są dwa sposoby układania jadłospisów dla diet. Pierwszy, bez bilansowania, na zasadzie wymiany potraw i produktów na podglądzie diety podstawowej. Drugi sposób to bilansowanie diety w kreatorze jadłospisu. Jeśli chcesz zbilansować dietę i nanieść na nią wiele zmian skorzystaj z przycisku "Zbilansuj dietę". Program przeniesie Cię do kreatora.',
      ],
    },
    target: {
      selector: '#menu-details-balance-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      lockDropdown();

      return unsubscribe;
    },
    previous: 'menu-preview-page-recalculation-button',
    next: 'menu-preview-dropdown-delete-button',
  },
  {
    id: 'menu-preview-dropdown-delete-button--no-balance-button',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Usuń',
      text: [
        'Usunięcie jadłospisu. Jeśli niechcący usuniesz jadłospis, nie panikuj! Tak naprawdę zostanie on przeniesiony do kosza na 30 dni. W tym czasie możesz przywrócić go z powrotem na główną listę jadłospisów.',
      ],
    },
    target: {
      selector: '#menu-details-delete-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      return subscribeToDisableOnHover({ targetElement });
    },
    previous: 'menu-preview-dropdown-recalculation-button--no-balance-button',
    next: 'menu-preview-page-profile',
  },

  {
    id: 'menu-preview-dropdown-delete-button',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      title: 'Usuń',
      text: [
        'Usunięcie jadłospisu. Jeśli niechcący usuniesz jadłospis, nie panikuj! Tak naprawdę zostanie on przeniesiony do kosza na 30 dni. W tym czasie możesz przywrócić go z powrotem na główną listę jadłospisów.',
      ],
    },
    target: {
      selector: '#menu-details-delete-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      return subscribeToDisableOnHover({ targetElement });
    },
    previous: 'menu-preview-dropdown-balance-button',
  },
  {
    id: 'menu-preview-page-profile',
    route: ['/menus/preview/{*}/{*}/{*}', '/menus/preview/{*}/{*}', '/menus/preview/{*}'],
    content: {
      text: [
        'Na stronie "Profilu" zmodyfikujesz dane swojego konta (adres e-mail oraz hasło).',
        <span className=" text-primary-500">{`Kliknij w "Profil".`}</span>,
      ],
    },
    target: {
      selector: '#profile-link-top-bar',
      padding: { right: -25 },
      ignoreNavbarOffset: true,
    },
    positioning: {
      align: 'bottom',
      justify: 'center',
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();
      return () => null;
    },
    canGoNext: false,
  },
];
