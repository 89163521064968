import React from 'react';
import Modal from '@Components/Modal/Modal';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import Paragraph from '@Components/Typography/Paragraph';

export default function LoaderModal() {
  const isOpen = useSelector((state) => state.loaderModal.isOpen);
  const text = useSelector((state) => state.loaderModal.text);
  if (!isOpen) return null;

  return (
    <Modal width={220} height={'12rem'} title={<div className={'text-center'}>Proszę czekać</div>} isOpen={isOpen}>
      <div className="flex items-center justify-center">
        <Loader type="TailSpin" color="#2BAD60" className={' w-24 h-24 '} />
      </div>
      {text && <Paragraph>{text}</Paragraph>}
    </Modal>
  );
}
