import React from 'react';
import Heading2 from '@Components/Typography/Heading2';
import ParagraphBold from '@Components/Typography/ParagraphBold';
export default function ErrorPageComponent() {
  return (
    <div className="flex flex-col gap-y-4 p-5">
      <Heading2>Coś poszło nie tak... przepraszamy!</Heading2>
      <ParagraphBold>
        Jeżeli problem się powtarza, skontaktuj się z nami mailowo. Opisz problem i wyślij wiadomość na
        kontakt@intendentpro.pl
      </ParagraphBold>

      <a href="/" className={'font-semibold underline cursor-pointer'}>
        Strona główna
      </a>
      <div>
        <svg width="466" height="426" viewBox="0 0 466 426" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="44" y="101" width="422" height="112" rx="10" fill="#F2F4F8" />
          <rect x="338.5" y="124.5" width="103" height="71" rx="9.5" fill="#E0E1E3" stroke="#EC1111" />
          <rect x="68" y="124" width="262" height="72" rx="10" fill="#FAD5D0" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M143.001 77.7069C154.43 77.7069 158.587 64.0241 165.08 56.5153C169.725 51.143 177.654 52.7449 180.001 45.2238C185.903 26.3111 170.107 13 149.501 13C128.895 13 115.001 24.6743 112.001 42.2238C109.001 59.7733 122.395 77.7069 143.001 77.7069Z"
            fill="#701D12"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M155.935 75.2443C162.411 79.1475 168.774 81.0333 171.76 80.2635C179.25 78.333 179.938 50.9351 174.143 40.1339C168.349 29.3328 138.558 24.625 137.057 46.0255C136.536 53.4529 139.654 60.0814 144.262 65.4901L136 104H160L155.935 75.2443Z"
            fill="#B28B67"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.618 31.0002C120.235 49.0002 138.689 68.132 143.745 68.132C144.071 65.5349 145.067 63.0086 146.526 60.6041C145.751 59.6006 145.292 58.3527 145.292 57.0002C145.292 53.6865 148.048 51.0002 151.448 51.0002C152.51 51.0002 153.509 51.2622 154.381 51.7236C161.751 45.4381 170.995 41.0563 175.585 40.1947C183.568 38.6961 157.002 13.0002 138.618 31.0002Z"
            fill="#701D12"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M128 187L165.631 310.024L183.86 414.648H198.153L178.891 187H128Z"
            fill="#997659"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M114.537 187C117.093 254.547 114.084 289.389 113.512 295.526C112.94 301.663 105.107 341.455 86.5625 416.708L101.793 411.478C131.101 342.459 141.088 306.589 144.449 297.526C147.811 288.463 156.738 251.621 171.232 187H114.537Z"
            fill="#B28B67"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M89.4496 410.284C88.3779 409.813 87.1043 410.29 86.7051 411.391C85.977 413.398 85 416.409 85 418.106C85 420.811 85.9012 426 85.9012 426C89.0392 426 142.303 426 142.303 426C142.303 426 144.502 418.732 139.765 418.152C135.028 417.573 131.895 417.106 131.895 417.106L105.68 405.419C105.165 405.189 104.563 405.43 104.348 405.951L102.663 410.039C102.663 410.039 98.2782 412.085 95.5367 412.085C93.8677 412.085 91.2457 411.074 89.4496 410.284Z"
            fill="#E0E1E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M183.45 410.284C182.378 409.813 181.104 410.29 180.705 411.391C179.977 413.398 179 416.409 179 418.106C179 420.811 179.901 426 179.901 426C183.039 426 236.303 426 236.303 426C236.303 426 238.502 418.732 233.765 418.152C229.028 417.573 225.895 417.106 225.895 417.106L199.68 405.419C199.165 405.189 198.563 405.43 198.348 405.951L196.663 410.039C196.663 410.039 192.278 412.085 189.537 412.085C187.868 412.085 185.246 411.074 183.45 410.284Z"
            fill="#E0E1E3"
          />
          <path fillRule="evenodd" clipRule="evenodd" d="M123 187L139 249.68H187.87L180.584 187H123Z" fill="#008479" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M113.999 187C106.102 210.671 110.999 249.68 110.999 249.68H160.87L174.583 187H113.999Z"
            fill="#00BAAB"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M256.235 143.704L220 159.617L231.57 174.439L258.707 153.633C272.262 151.143 278.549 149.004 277.566 147.216C276.738 145.709 275.197 145.624 273.778 145.545C272.672 145.484 271.64 145.427 271.076 144.705C269.789 143.056 272.966 137.961 276.188 133.255C279.41 128.549 276.39 128.719 274.623 129.282C268.711 132.572 262.582 137.379 256.235 143.704ZM94 168.066L73.5083 164C59.6109 200.63 51.3256 220.702 48.6523 224.218C46.692 226.796 34.6026 233.437 26.4198 236.984C23.1354 238.407 23.8406 241.183 24.8039 242.071C30.8802 247.672 36.5292 245.709 42.6783 243.572C44.4585 242.953 46.2806 242.32 48.1672 241.851C53.0502 240.639 54.5361 243.15 55.9404 245.523C57.1837 247.624 58.363 249.616 61.7793 248.821C61.6239 248.151 61.433 247.4 61.227 246.589C59.8952 241.349 57.9312 233.62 60.845 229.13C62.3404 226.826 73.3921 206.471 94 168.066Z"
            fill="#B28B67"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M149.696 87.3321L158.343 85.8489C177.178 104.119 184.863 148.012 197.415 153.823C209.282 159.316 226.105 154 246.101 146.823L252.225 160.505C234.056 180.161 195.35 196.767 179.661 187.287C154.578 172.13 148.787 117.994 149.696 87.3321Z"
            fill="#006C63"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112 196C112 196 164.008 196 188.511 196C192.008 196 191.349 190.952 190.843 188.404C185.011 159.004 162.241 127.312 162.241 85.4607L140.172 82C121.917 111.358 115.605 147.505 112 196Z"
            fill="#E0E1E3"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M139 82H138.748C89.2466 112.504 59.3306 159.791 49 223.859L65.0374 227C75.6609 214.623 88.7714 194.368 104.369 166.233C99.5834 183.181 96.0896 200.563 95 217H163.872C177.205 183.305 147.486 82 147.486 82H142.731H139Z"
            fill="#006C63"
          />
        </svg>
      </div>
    </div>
  );
}
