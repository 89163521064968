import React from 'react';
import { RawTutorialStep } from '../../types';

export const menuEditing: RawTutorialStep[] = [
  {
    id: 'new-menu-name',
    route: '/menus/edit/{*}',
    content: {
      title: 'Edycja nazwy jadłospisu',
      text: [''],
    },
    target: {
      selector: '#menu-name',
      focus: true,
      padding: {
        top: 35,
        // left: 5,
        bottom: 25,
      },
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();
      return () => null;
    },
    previous: 'menu-dropdown-button-button',
  },
  {
    route: '/menus/edit/{*}',
    content: {
      title: 'Edycja rodzaju placówki',
      text: [''],
    },
    target: {
      selector: '#menu-institution',
      padding: {
        top: 35,
        left: 5,
        bottom: 15,
      },
    },
  },
  {
    id: 'new-menu-date-range',
    route: '/menus/edit/{*}',
    content: {
      title: 'Edycja przedziału czasowego jadłospisu',
      text: [''],
    },
    target: {
      selector: '#date-range-input',
      padding: {
        top: 35,
        left: 5,
        bottom: 5,
      },
    },
  },
  {
    route: '/menus/edit/{*}',
    content: {
      title: 'Uwzględniaj dni wolne',
      text: [
        'Jeśli Twój jadłospis ma uwzględnić soboty i/lub niedziele, zaznacz opcję "Uwzględniaj soboty i/lub niedziele". Jeśli nie układasz jadłospisów z weekendem nie zaznaczaj tej opcji.',
      ],
    },
    target: {
      selector: '#menu-includeDaysOff',
      padding: 5,
    },
  },
  {
    route: '/menus/edit/{*}',
    content: {
      title: 'Uwzględniaj dni świąteczne',
      text: [
        'Jeśli Twój jadłospis ma uwzględnić kalendarzowe dni wolne od pracy, zaznacz opcję "Uwzględniaj ',
        'dni świąteczne". Jeśli nie układasz jadłospisu z dniami świątecznymi nie zaznaczaj tej opcji.',
      ],
    },
    target: {
      selector: '#menu-includeHolidays',
      padding: 5,
    },
  },
  {
    route: '/menus/edit/{*}',
    content: {
      title: 'Diety w placówce',
      text: [
        'Wybierz rodzaje diet, dla których chcesz ułożyć jadłospisy na bazie diety podstawowej. ',
        <b className="font-semibold">Dla samouczka oznacz wszytkie diety.</b>,
      ],
    },
    target: {
      selector: '#menu-diets-in-institution',
      padding: 5,
    },
  },
  {
    route: '/menus/edit/{*}',
    content: {
      title: 'Sprawdź, czy wszystko się zgadza.',
      text: [<span className="text-primary-500">Jeśli tak, kliknij w przycisk &quot;Dalej&quot;.</span>],
    },
    target: {
      selector: '#tab-button-next',
      padding: 5,
    },
    canGoNext: false,
  },
];
