import { Tutorial, TutorialStep } from '../types';
import { routeContainsPlaceholder } from './routeUtils';
import { Inertia } from '@inertiajs/inertia';

export const navigateToCurrentStep = (
  currentStep: TutorialStep | null,
  tutorial: Tutorial | null,
  setCurrentStepById: (stepId: string) => void
) => {
  if (!tutorial || !currentStep?.route) return;

  if (routeContainsPlaceholder(currentStep.route)) {
    Inertia.visit('/', {
      onSuccess: () => {
        const firstStepId = tutorial?.steps[0]?.id;
        setCurrentStepById(firstStepId);
      },
    });
  } else {
    Inertia.visit(currentStep.route[0]);
  }
};
