export const createElementObserver = (selector: string, onElementFound: (element: HTMLElement) => void) => {
  const observer = new MutationObserver((mutations) => {
    const target = document.querySelector(selector) as HTMLElement;
    if (target) {
      onElementFound(target);
      observer.disconnect();
    }
  });

  observer.observe(document.body, {
    childList: true,
    subtree: true,
    attributes: true,
  });

  return observer;
};
