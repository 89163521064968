import { Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { toast } from 'react-toastify';

export const handleTutorialMenuCreation = async (dispatch: Dispatch, institutionId: number) => {
  if (!institutionId) {
    toast.error('Nie można znaleźć ID instytucji');
    return;
  }

  const response = await createTutorialMenu({ institutionId });
};
const createTutorialMenu = async (options: { institutionId: number }) => {
  try {
    const response = await axios.post(route('api.v2.tutorial.createMenu'), {
      institution_id: options.institutionId,
    });

    if (!response.data?.success) return null;

    return response.data;
  } catch (error) {
    toast.error('Nie udało się utworzyć jadłospisu samouczka');
    throw error;
  }
};
