export const OPEN_MODAL_PROGRESS = 'OPEN_MODAL_PROGRESS';
export const CLOSE_MODAL_PROGRESS = 'CLOSE_MODAL_PROGRESS';
export const REFRESH_CURRENT_STEP_PROGRESS_MODAL = 'REFRESH_CURRENT_STEP_PROGRESS_MODAL';
export const INCREASE_CURRENT_STEP_PROGRESS_MODAL = 'INCREASE_CURRENT_STEP_PROGRESS_MODAL';
export const SET_LOADED_PROGRESS_MODAL = 'SET_LOADED_PROGRESS_MODAL';
export const CHANGE_TITLE_AND_INCREASE_CURRENT_STEP_PROGRESS_MODAL =
  'CHANGE_TITLE_AND_INCREASE_CURRENT_STEP_PROGRESS_MODAL';
export const openProgressModal = (title: string, text: string, steps: number, currentStep: number) => ({
  type: OPEN_MODAL_PROGRESS,
  payload: { title, text, steps, currentStep },
});
export const closeModal = () => ({ type: CLOSE_MODAL_PROGRESS });

export const refreshCurrentStepProgressModal = (currentStep: number) => ({
  type: REFRESH_CURRENT_STEP_PROGRESS_MODAL,
  payload: { currentStep },
});

export const increaseCurrentStepProgressModal = () => ({
  type: INCREASE_CURRENT_STEP_PROGRESS_MODAL,
});

export const setLoadedProgressModal = () => ({
  type: SET_LOADED_PROGRESS_MODAL,
});

export const changeTitleAndIncreaseCurrentStepProgressModal = (title: string) => ({
  type: CHANGE_TITLE_AND_INCREASE_CURRENT_STEP_PROGRESS_MODAL,
  payload: { title },
});
