import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const productCard: RawTutorialStep[] = [
  {
    id: 'product-card-page-product-details-page',
    route: '/products/details/{*}',
    content: {
      title: 'Dodałaś/eś nowy produkt do Bazy własnej.',
      text: ['Sprawdź, czy wszystko się zgadza.'],
    },

    target: {
      selector: '#product-details-page',
      padding: { top: -5 },
    },
    positioning: {
      align: 'center',
      justify: 'center',
    },
    next: 'product-card-page-alternative-product-button',
  },
  {
    id: 'product-card-page-alternative-product-button-when-no-alternative',
    route: '/products/details/{*}',
    content: {
      title: 'Karta produktu',
      text: ['Dodałaś/eś nowy produkt do Bazy własnej.'],
    },
    target: {
      selector: '#product-details-page',
      padding: { top: -5 },
    },
    positioning: {
      align: 'center',
      justify: 'center',
    },
    next: 'product-card-page-duplicate-product-button-when-no-alternative',
    previous: 'product-new-product-form-submit',
  },
  {
    id: 'product-card-page-alternative-product-button',
    route: '/products/details/{*}',
    content: {
      title: 'Zamiennik alergenu',
      text: [
        'Z poziomu karty produktu możesz wybrać zamiennik dla diet.',
        <span className="text-primary-500">Kliknij w przycisk "Zamiennik alergenu".</span>,
      ],
    },
    target: {
      selector: '#alternative-product-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, nextStep }) =>
      subscribeToNextStep({
        targetElement,
        nextStep,
      }),
    canGoNext: false,
    previous: 'product-card-page-product-details-page',
  },
  {
    route: '/products/details/{*}',
    content: {
      title: 'Wymień lub pomiń w potrawie',
      text: [
        'Produkt może zawierać jeden lub więcej alergenów. Wybierz zamiennik w danej diecie.',
        'Funkcja "Pomiń w potrawie" spowoduje, że produkt zawierający alergen zostanie pominięty w ',
        'potrawie i nie wyświetli się na jadłospisie. ',
      ],
    },
    target: {
      selector: '#modal',
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      const skipButtons = Array.from(
        targetElement?.querySelectorAll('#skip-alternative-product-button') || []
      ) as HTMLButtonElement[];
      const changeButtons = Array.from(
        targetElement?.querySelectorAll('#change-alternative-product-button') || []
      ) as HTMLButtonElement[];

      const unsubscribe = subscribeToNextStep({
        targetElement: button,
        nextStep,
      });

      const skipButtonCleanups = skipButtons.map((btn) => subscribeToDisableOnHover({ targetElement: btn }));
      const changeButtonCleanups = changeButtons.map((btn) => subscribeToDisableOnHover({ targetElement: btn }));

      return () => {
        unsubscribe();
        skipButtonCleanups.forEach((cleanup) => cleanup());
        changeButtonCleanups.forEach((cleanup) => cleanup());
      };
    },
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
  },
  {
    id: 'product-card-page-duplicate-product-button',
    route: '/products/details/{*}',
    content: {
      title: 'Powiel',
      text: [
        'Funkcję „Powiel” wykorzystaj, jeśli chcesz wprowadzić do Bazy własnej podobny produkt. Na uzupełniony formularz nanieś tylko potrzebne zmiany i zapisz dane. W ten sposób szybciej poszerzysz Bazę własną. ',
      ],
    },

    target: {
      selector: '#duplicate-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement, setCurrentStepById }) => {
      const button = document.querySelector('#alternative-product-button') as HTMLButtonElement;

      if (!button) {
        setCurrentStepById('product-card-page-duplicate-product-button-when-no-alternative');
      }

      return subscribeToDisableOnHover({ targetElement });
    },
    previous: 'product-card-page-alternative-product-button',
    next: 'product-card-page-dish-link',
  },

  {
    id: 'product-card-page-duplicate-product-button-when-no-alternative',
    route: '/products/details/{*}',
    content: {
      title: 'Powiel',
      text: [
        'Funkcja "Powiel" służy do szybkiego dodawania do Bazy własnej produktów podobnych do tych, które już istnieją w Bazie Intendent Pro.',
        'W wypełnionym formularzu podobnego produktu wprowadź potrzebne zmiany, np. uzupełnij jego nazwę i zatwierdź dane. W ten sposób szybko poszerzysz Bazę własną.',
      ],
    },

    target: {
      selector: '#duplicate-button',
      padding: 0,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement }) => {
      return subscribeToDisableOnHover({ targetElement });
    },
    previous: 'product-card-page-alternative-product-button-when-no-alternative',
  },

  //------------------------------------------------------

  {
    id: 'product-card-page-dish-link',
    route: '/products/details/{*}',
    content: {
      title: 'Twoja baza potraw',
      text: [<span className=" text-primary-500">Kliknij w przycisk „Potrawy”.</span>],
    },
    target: {
      selector: '#dishes-link',
      padding: 3,
    },
    canGoNext: false,
  },
];
