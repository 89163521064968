import React from 'react';
import { RawTutorialStep } from '../../types';
import {
  subscribeToClick,
  subscribeToDisableOnHover,
  subscribeToModalList,
  subscribeToNextStep,
} from '../../utils/subscribeToClick';

export const newDishPage: RawTutorialStep[] = [
  {
    id: 'dish-name-input',
    route: '/dishes/new',
    content: {
      title: 'Nazwa potrawy',
      text: ['Wpisz nazwę potrawy.', <span className="font-semibold">Dla samouczka wpisz- Samouczek potrawa.</span>],
    },
    target: {
      selector: '#dish-name',
      padding: { top: 40, bottom: 30 },
    },
  },
  {
    id: 'dish-group-container',
    route: '/dishes/new',
    content: {
      title: 'Grupa potraw do Księgi HACCP',
      text: ['Wybierz odpowiednią grupę.', <span className="font-semibold">Dla samouczka wybierz- Dania mięsne.</span>],
    },
    target: {
      selector: '#dish-group-container',
    },
  },
  {
    id: 'dish-meal-category-container',
    route: '/dishes/new',
    content: {
      title: 'Kategoria posiłku',
      text: [
        'Oznacz odpowiednią kategorię posiłku. Potrawa może należeć do więcej niż jednej kategorii.',
        <span className="font-semibold">Dla Samouczka wybierz- Śniadanie.</span>,
      ],
    },
    target: {
      selector: '#dish-meal-category-container',
    },
  },
  {
    id: 'dish-new-age-range-container',
    route: '/dishes/new',
    content: {
      title: 'Przedział wiekowy',
      text: [
        'Wybierz przedział wiekowy, dla którego dedykowana jest nowa potrawa.',
        <span className="font-semibold">Dla samouczka wybierz- swój rodzaj placówki.</span>,
      ],
    },
    target: {
      selector: '#dish-age-range-container',
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
  },
  {
    route: '/dishes/new',
    content: {
      title: 'Potrawa smażona',
      text: ['Zaznaczaj to pole, jeśli potrawa jest smażona.'],
    },
    target: {
      selector: '#dish-fried-container',
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
  },
  {
    route: '/dishes/new',
    content: {
      title: 'Czas przygotowania potrawy',
      text: ['Określ czas potrzebny do przygotowania potrawy (dane do Księgi HACCP).'],
    },
    target: {
      selector: '#dish-time-of-prepare-container',
      padding: { top: 40 },
    },
  },
  {
    route: '/dishes/new',
    content: {
      title: 'Wybierz odpowiedni sposób podania potrawy.',
      text: [''],
    },
    target: {
      selector: '#dish-way-of-serving-container',
    },
  },
  {
    id: 'dish-tab-next-button',
    route: '/dishes/new',
    content: {
      text: [
        <span className="font-semibold text-primary-500">
          Sprawdź, czy wszystkie dane są poprawne i kliknij w przycisk „Dalej”.
        </span>,
      ],
    },
    target: {
      selector: '#tab-button-next',
    },
    onMount: ({ targetElement, nextStep }) =>
      subscribeToNextStep({
        targetElement,
        nextStep,
      }),
    canGoNext: false,
    next: 'tab-button-composition-and-recipes',
  },

  //------------------------------------------------Składniki i przepisy ----------------------------------------------------
  {
    route: '/dishes/new',
    content: {
      text: ['Abu wrócić do ustawień. ', <span className=" text-primary-500">Kliknij w przycisk „Ustawienia”.</span>],
    },
    target: {
      selector: '#tab-button-Ustawienia',
      padding: 10,
    },

    canGoPrevious: false,
    onMount: ({ targetElement, previousStep }) => {
      if (!targetElement) return;
      const handleClick = () => {
        previousStep();
      };

      return subscribeToClick({
        targetElement,
        onClick: handleClick,
      });
    },
  },
  {
    id: 'tab-button-composition-and-recipes',
    route: '/dishes/new',
    content: {
      title: 'Planowana wielkość porcji',
      text: [
        'Określ wielkość porcji podawanej na talerzu.',
        <span className="font-semibold text-black ">Wpisz wartość 200 na potrzeby Samouczka.</span>,
      ],
    },
    target: {
      selector: '#dish-planned-size-of-servings-container',
      padding: 10,
    },
  },
  {
    id: 'dish-number-of-servings',
    route: '/dishes/new',
    content: {
      title: 'Liczba porcji',
      text: [
        'Wpisz, dla ilu porcji podajesz gramaturę składników.',
        <span className="font-semibold text-black ">Pozostaw 1 porcję na potrzeby Samouczka.</span>,
      ],
    },
    target: {
      selector: '#dish-number-of-servings-container',
      padding: 10,
    },
  },
  {
    route: '/dishes/new',
    content: {
      title: 'Sposób podania wagi składników',
      text: [
        <span>
          <b className="font-semibold">Waga netto</b> to masa bez odpadu (produkt po obróbce wstępnej).
        </span>,
        <span>
          <b className="font-semibold">Waga rynkowa</b> to masa wraz z odpadem (produkt przed obróbką wstępną).
        </span>,
      ],
    },
    target: {
      selector: '#dish-weight-type-container',
      padding: 10,
    },
  },
  {
    id: 'dish-add-ingredient-button',
    route: '/dishes/new',
    content: {
      title: 'Dodaj składniki do nowej potrawy',
      text: [
        'W tym miejscu dodasz składniki i określisz ich gramatury.',
        <span className=" text-primary-500">Kliknij w przycisk „Dodaj składnik”.</span>,
      ],
    },
    target: {
      selector: '#ingredients-header',
      padding: 10,
    },
    onMount: ({ targetElement, nextStep }) => {
      const button = targetElement?.querySelector('#add-ingredient-button') as HTMLButtonElement;
      return subscribeToNextStep({
        targetElement: button,
        nextStep,
      });
    },
    canGoNext: false,
  },
  {
    id: 'new-dish-add-ingredient-modal',
    route: '/dishes/new',
    content: {
      title: 'Okno z listą produktów',
      text: [
        'W pierwszej kolejności wybierz wszystkie składniki potrawy z listy. Wyszukuj je w wyszukiwarce po nazwie, a następnie potwierdzaj wybór klikając w znak „+”. ',
        <span className="font-semibold text-black-500">Na potrzeby Samouczka dodaj „Mleko krowie 2%”.</span>,
      ],
    },
    target: {
      selector: '#modal',
      padding: 0,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    canGoNext: false,
    onMount: ({ targetElement, nextStep }) => subscribeToModalList({ targetElement, nextStep }),
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
  },
  {
    id: 'add-milk-ingredient-step',
    route: '/dishes/new',
    content: {
      title: 'Gramatury składników',
      text: [
        <span className="font-semibold ">Wpisz gramatury składników.</span>,
        'Zawsze możesz dodać lub usunąć składnik z potrawy klikając w przyciski „Dodaj składnik” lub „Usuń”.',
      ],
    },
    target: {
      selector: '#ingredients-list',
    },
    onMount: ({ targetElement }) => {
      const removeButtons = targetElement?.querySelectorAll('#remove-button') as NodeListOf<HTMLButtonElement>;

      const cleanupFunctions = [
        ...Array.from(removeButtons || []).map((button) => subscribeToDisableOnHover({ targetElement: button })),
      ];

      return () => cleanupFunctions.forEach((cleanup) => cleanup?.());
    },

    previous: 'dish-add-ingredient-button',
  },
  {
    id: 'new-dish-recipe-container',
    route: '/dishes/new',
    content: {
      title: 'Przepis',
      text: ['Przepis będzie potrzebny do wydruku receptury do księgi HACCP. '],
    },
    target: {
      selector: '#dish-recipe-container',
    },
  },
  {
    route: '/dishes/new',
    content: {
      title: 'Autor receptury',
      text: ['Wskaż autora receptury. Pole do wpisania nowych autorów znajduje się w zakładce Placówki.'],
    },
    target: {
      selector: '#dish-recipe-author-container',
    },
  },
  {
    id: 'dish-alternative-replacment-button',
    route: '/dishes/new',
    content: {
      title: 'Zamiennik dla diet',
      text: [
        'Jeśli układasz jadłospisy dla diet, wybierz potrawę alternatywną spełniającą kryteria tych diet. ',
        <span className=" text-primary-500">Kliknij w przycisk „Wymień potrawę”.</span>,
      ],
    },
    target: {
      selector: '#dish-alternative-replacment-container',
    },
    onMount: ({ targetElement, nextStep }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      return subscribeToNextStep({
        targetElement: button,
        nextStep,
      });
    },
    canGoNext: false,
  },
  {
    route: '/dishes/new',
    content: {
      title: 'Wybierz potrawę z listy',
      text: [
        'Wybierz zamiennik dla diety bezmlecznej. ',
        <span className=" text-primary-500">Na potrzeby Samouczka wybierz: </span>,
        <span className=" text-primary-500">
          Płatki kukurydziane na napoju sojowym (nie nabiałowym) klikając w „+”.
        </span>,
      ],
    },
    target: {
      selector: '#modal',
      padding: 0,
    },
    positioning: {
      align: 'top',
      justify: 'center',
    },
    onMount: ({ targetElement, nextStep }) => subscribeToModalList({ targetElement, nextStep }),
    onPrevious: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;

      button?.click();
    },
    onNext: ({ targetElement }) => {
      const button = targetElement?.querySelector('button') as HTMLElement;
      button?.click();
    },
    canGoNext: false,
  },
  {
    id: 'new-dish-save-button',
    route: '/dishes/new',
    content: {
      title: 'Zapisz formularz nowej potrawy',
      text: [
        <span className=" text-primary-500">
          Sprawdź zawsze, czy wszystkie dane są poprawne i kliknij przycisk „Zapisz”.
        </span>,
      ],
    },
    target: {
      selector: '#tab-button-submit',
    },
    canGoNext: false,
    previous: 'dish-alternative-replacment-button',
  },
];
