import React, { CSSProperties } from 'react';
import cx from 'classnames';
import { ClipLoader } from 'react-spinners';
import { clsx } from 'clsx';

type Props = {
  id?: string;
  type?: 'submit' | 'button' | 'reset';
  style?: CSSProperties | undefined;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  icon?: React.FunctionComponent<any>;
  label?: string;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
};

export default function PrimaryButton(props: Props) {
  return (
    <button
      {...props}
      className={clsx(
        'text-paragraph-bold rounded outline-none border-none flex-none cursor-pointer p-4 bg-primary-500 text-grayscale-0 flex items-center justify-center hover:bg-primary-400 focus:bg-primary-400 active:bg-primary-700 disabled:bg-grayscale-100 disabled:shadow-border-disabled disabled:text-grayscale-400 disabled:cursor-not-allowed',
        props.className
      )}
      disabled={props.disabled || props.isLoading}
    >
      {props.isLoading && (
        <ClipLoader color={'#8C8F98'} className={'mr-2'} size={24} />
      )}
      {props.icon &&
        React.createElement(props.icon, {
          className: cx({ ['mr-2']: props.icon && props.label })
        })}
      {props.label}
    </button>
  );
}
