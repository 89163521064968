import React from "react";
import { SmallButton } from "./index";
import { XIcon } from "../../LegacyAssets/icons";

export interface Props {
  onClose: () => void
}

const ModalCloseButton: React.FC<Props> = (props: Props) => {
  const handleClose = () => {
    props.onClose();
  }
  return <SmallButton icon={XIcon} onClick={handleClose} width={'auto'}/>
}

export default ModalCloseButton
