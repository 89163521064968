import React from 'react';
import Modal from '@Components/Modal/Modal';
import { useSelector } from 'react-redux';
import Loader from 'react-loader-spinner';
import Paragraph from '@Components/Typography/Paragraph';

export default function ProgressModal() {
  const isOpen = useSelector((state) => state.progressModal.isOpen);
  const text = useSelector((state) => state.progressModal.text);
  const title = useSelector((state) => state.progressModal.title);
  const steps = useSelector((state) => state.progressModal.steps);
  const currentStep = useSelector((state) => state.progressModal.currentStep);
  if (!isOpen) return null;

  return (
    <Modal width={400} height={'17rem'} title={title} isOpen={isOpen}>
      <div className="flex items-center justify-center">
        <Loader type="TailSpin" color="#2BAD60" className={' w-24 h-24 '} />
      </div>
      <div className="w-full mt-4 bg-gray-200 rounded-full h-4 overflow-hidden">
        <div
          className="bg-primary-500 h-full transition-all duration-500"
          style={{ width: `${(currentStep / steps) * 100}% ` }}
        ></div>
      </div>
      <Paragraph className="mt-2 text-center">{text}</Paragraph>
    </Modal>
  );
}
