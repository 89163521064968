import React, { HTMLAttributes } from 'react';
import cx from 'classnames';

export default function Heading2({
  className,
  children,
  ...rest
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <h2 className={cx('text-headline2', className)} {...rest}>
      {children}
    </h2>
  );
}
