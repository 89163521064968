import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToClick, subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const editCardPage: RawTutorialStep[] = [
  {
    id: 'dish-age-range-container',
    route: '/dishes/edit/{*}',
    content: {
      text: [
        <span>
          Zmień <b className="font-semibold ">przedział wiekowy</b> na docelowy, a następnie
        </span>,
        'kliknij w przycisk "Dalej".',
      ],
    },
    target: {
      selector: '#dish-age-range-container',
    },
    positioning: {
      justify: 'center',
      align: 'top',
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();

      return () => null;
    },
    onNext: () => {
      const tabButton = document.querySelector('#tab-button-Składniki-i-przepis') as HTMLButtonElement;
      tabButton?.click();
    },
    next: 'recalculate-button',
  },

  //------------------------------------------------Składniki i przepisy ----------------------------------------------------
  {
    route: '/dishes/edit/{*}',
    content: {
      text: ['Abu wrócić do ustawień. ', <span className=" text-primary-500">Kliknij w przycisk „Ustawienia”.</span>],
    },
    target: {
      selector: '#tab-button-Ustawienia',
      padding: 10,
    },

    canGoPrevious: false,
    onMount: ({ targetElement, previousStep }) => {
      if (!targetElement) return;
      const handleClick = () => {
        previousStep();
      };

      return subscribeToClick({
        targetElement,
        onClick: handleClick,
      });
    },
  },
  {
    id: 'recalculate-button',
    route: '/dishes/edit/{*}',
    content: {
      text: [
        <span className="">
          Zmień <b className="font-semibold ">wielkość porcji na 300</b>
        </span>,
        <span className=" text-primary-500">i kliknij w przycisk "Przelicz gramatury".</span>,
      ],
    },
    target: {
      selector: '#dish-planned-size-of-servings-container',
      padding: 10,
    },
    onMount: ({ targetElement, nextStep }) => {
      if (!targetElement) return;
      const recalculateButton = targetElement.querySelector(
        '#dish-recalculate-ingredients-button'
      ) as HTMLButtonElement;

      return subscribeToNextStep({
        targetElement: recalculateButton,
        nextStep,
      });
    },
    canGoNext: false,
  },
  {
    route: '/dishes/edit/{*}',
    content: {
      text: ['Gramatury wszystkich składników zostały przeliczone zgodnie z nową wielkością porcji.'],
    },
    target: {
      selector: '#ingredients-list',
      padding: 10,
    },
  },
  {
    route: '/dishes/edit/{*}',
    content: {
      text: [
        <span className="font-semibold ">
          Nowa potrawa z przeliczonymi gramaturami zostanie zapisana w Bazie własnej.
        </span>,
      ],
    },
    target: {
      selector: '#tab-button-submit',
      padding: 10,
    },
    onMount: ({ targetElement }) => {
      return subscribeToDisableOnHover({
        targetElement,
      });
    },
  },

  {
    id: 'product-card-page-menu-link',
    route: '/dishes/edit/{*}',
    content: {
      title: 'Jadłospisy',
      text: [
        'W tej zakładce ułożysz jadłospis.',
        <span className=" text-primary-500">Kliknij w przycisk „Jadłospisy”.</span>,
      ],
    },
    target: {
      selector: '#menu-link',
      padding: 3,
    },
    canGoNext: false,
  },
];
