import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover } from '../../utils/subscribeToClick';
import { ReactComponent as Check } from '@Svg/check.svg';

export const institutionCard: RawTutorialStep[] = [
  {
    route: '/institutions/details/{*}',
    content: {
      title: 'Placówki',
      text: ['Poprzedni krok.', <span className=" text-primary-500">Kliknij w przycisk „Placówki”.</span>],
    },
    target: {
      selector: '#facilities-link',
      padding: 3,
    },
    previous: 'add-institution',
  },
  {
    id: 'institution-details',
    route: '/institutions/details/{*}',
    content: {
      title: 'Karta placówki',
      text: [
        'Spójrz na kartę swojej placówki.',
        <Check className="text-primary-500" />,
        'Sprawdź, czy wszystkie wpisane dane są poprawne.',
        <Check className="text-primary-500" />,
        'Wyliczone średnioważone normy żywieniowe ',
        'zostały już zapisane w Twoim kreatorze jadłospisów. ',
      ],
    },
    target: {
      selector: '#institution-details-container',
      padding: { top: 2, left: 10, bottom: 5, right: -600 },
    },
    scrollTo: 'start',
  },
  {
    route: '/institutions/details/{*}',
    content: {
      title: 'Wydruk karty placówki',
      text: ['Może być potrzebny podczas kontroli Sanepidu.'],
    },
    target: {
      selector: '#institution-details-print-button',
      padding: 3,
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },
    onMount: ({ targetElement }) => {
      if (!targetElement) return;
      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      return unsubscribe;
    },
  },
  {
    route: '/institutions/details/{*}',
    content: {
      title: 'Twoja baza produktów',
      text: [<span className=" text-primary-500">Kliknij w przycisk „Produkty”.</span>],
    },
    target: {
      selector: '#products-link',
      padding: 3,
    },
    canGoNext: false,
  },
];
