import {
  CLOSE_MODAL_PROGRESS,
  OPEN_MODAL_PROGRESS,
  REFRESH_CURRENT_STEP_PROGRESS_MODAL,
  INCREASE_CURRENT_STEP_PROGRESS_MODAL,
  SET_LOADED_PROGRESS_MODAL,
  CHANGE_TITLE_AND_INCREASE_CURRENT_STEP_PROGRESS_MODAL
} from '@/redux/actions/progressModalActions';

const initialState = {
  isOpen: false,
  text: '',
  title: '',
  currentStep: 1,
  steps: 1,
};

const progressModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_PROGRESS:
      return {
        ...state,
        isOpen: true,
        title: action.payload.title,
        text: action.payload.text,
        currentStep: action.payload.currentStep,
        steps: action.payload.steps,
      };
    case INCREASE_CURRENT_STEP_PROGRESS_MODAL: {
      return { ...state, currentStep: state.currentStep + 1 };
    }
    case CLOSE_MODAL_PROGRESS:
      return { ...state, isOpen: false };
    case REFRESH_CURRENT_STEP_PROGRESS_MODAL:
      return {
        ...state,
        currentStep: action.payload.currentStep,
      };
    case SET_LOADED_PROGRESS_MODAL: {
      return {
        ...state,
        currentStep: 1,
        steps: 1,
      };
    }
    case CHANGE_TITLE_AND_INCREASE_CURRENT_STEP_PROGRESS_MODAL: {
      return {
        ...state,
        currentStep: state.currentStep + 1,
        title: state.title,
      };
    }
    default:
      return state;
  }
};

export default progressModalReducer;
