import { CLOSE_MODAL, OPEN_MODAL_USER_MUST_DUPLICATE } from '@/redux/actions/userMustDuplicateModalActions';

const initialState = {
  isOpen: false,
  menuId: null,
};

const userMustDuplicateModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL_USER_MUST_DUPLICATE:
      return { ...state, isOpen: true, menuId: action.payload.menuId };
    case CLOSE_MODAL:
      return { ...state, isOpen: false, menuId: null };
    default:
      return state;
  }
};

export default userMustDuplicateModalReducer;
