import React from 'react';
import CheckHasLatestInstitutionInMenu from '@Components/Modal/CheckHasLatestInstitutionInMenu';
import UserMustDuplicateMenuModal from '@Components/Modal/UserMustDuplicateMenuModal';
import LoaderModal from '@Components/Modal/LoaderModal';
import ProgressModal from '@Components/Modal/ProgressModal';

export default function ModalsRedux() {
  return (
    <>
      <CheckHasLatestInstitutionInMenu />
      <UserMustDuplicateMenuModal />
      <LoaderModal />
      <ProgressModal />
    </>
  );
}
