import React from 'react';
import Modal from '@Components/Modal/Modal';
import { useSelector } from 'react-redux';
import { Inertia } from '@inertiajs/inertia';
import PrimaryButton from '@Components/Buttons/PrimaryButton';
import ParagraphBold from '@Components/Typography/ParagraphBold';
import useUserMustDuplicateMenuModal from '@/Hooks/useUserMustDuplicateMenuModal';
import menu from '@/Pages/Dashboard/Menus/Menu';
import axios from 'axios';
import useLoaderModal from "@/Hooks/useLoaderModal";
import {toast} from "react-toastify";

export default function UserMustDuplicateMenuModal() {
  const isOpen = useSelector((state) => state.userMustDuplicateModal.isOpen);
  const menuId = useSelector((state) => state.userMustDuplicateModal.menuId);
  const { closeModalUserMustDuplicateMenuModal } = useUserMustDuplicateMenuModal();
  const { showLoaderModal, closeLoaderModal } = useLoaderModal();

  if (!isOpen) return null;
  const handleVisitDuplicatePage = () => {
    showLoaderModal();
    axios
      .post(route('api.v2.menus.duplicate', { menuId: menuId }), {})
      .then((data) => {
        Inertia.visit(route('menus.edit', { menuId: data.data.menu.id, duplication: true }), {
          onStart: () => {
            closeModalUserMustDuplicateMenuModal();
          },
          onFinish: () => {
            closeLoaderModal();
          },
        });
      })
      .catch((error) => {
        toast.error('Błąd podczas powielania menu');
        closeModalUserMustDuplicateMenuModal();
        closeLoaderModal();
      });
  };

  return (
    <Modal width={600} height={'10rem'} title={'Nieaktualne normy żywieniowe w jadłospisie'} isOpen={isOpen}>
      <div className={'flex flex-row items-center justify-between gap-x-2'}>
        <ParagraphBold>Powiel jadłospis, aby móc kontynuować</ParagraphBold>
        <PrimaryButton label={'Powiel'} onClick={handleVisitDuplicatePage} />
      </div>
    </Modal>
  );
}
