import './bootstrap';
import 'animate.css/animate.min.css';
import 'croppie/croppie.css';
import 'react-toastify/dist/ReactToastify.css';
import '../css/app.scss';

import React from 'react';
import Modal from 'react-modal';
import './i18n';
import { createRoot } from 'react-dom/client';
import { InertiaProgress } from '@inertiajs/progress';
import { createInertiaApp } from '@inertiajs/inertia-react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactNotifications } from 'react-notifications-component';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import store from '@/redux/store';
import { CsrfTokenProvider } from '@/Contexts/CsrfTokenContext';
import ModalsRedux from './Components/Modal/ModalsRedux';
import { EventBusProvider } from './Hooks/useEventBus';
import ErrorPageComponent from '@Components/ErrorPageComponent';
import { FallbackProps } from 'react-error-boundary';

const app = document.getElementById('app');
Modal.setAppElement(app);

InertiaProgress.init({
  color: '#008479',
  showSpinner: true,
});

interface ErrorBoundaryProps {
  children: React.ReactNode;
  fallbackComponent: React.ComponentType<FallbackProps>;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): ErrorBoundaryState {
    return { hasError: true };
  }

  render(): React.ReactNode {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <ErrorPageComponent />;
    }

    return children;
  }
}

createInertiaApp({
  resolve: (name) => resolvePageComponent(`./Pages/${name}.tsx`, import.meta.glob('./Pages/**/*.tsx')),
  setup({ el, App, props }) {
    const queryClient = new QueryClient();
    createRoot(el).render(
      <ErrorBoundary>
        <Provider store={store}>
          <EventBusProvider>
            <CsrfTokenProvider>
              <QueryClientProvider client={queryClient}>
                <ToastContainer position={'top-right'} autoClose={2000} theme="light" />
                <ReactNotifications />
                <App {...props} />
                <ModalsRedux />
              </QueryClientProvider>
            </CsrfTokenProvider>
          </EventBusProvider>
        </Provider>
      </ErrorBoundary>
    );
  },
});
