import React, { useEffect, useRef } from 'react';
import Modal from '@Components/Modal/Modal';
import { useSelector } from 'react-redux';
import { Inertia } from '@inertiajs/inertia';
import PrimaryButton from '@Components/Buttons/PrimaryButton';
import ParagraphBold from '@Components/Typography/ParagraphBold';
import useCheckHasLatestInstitutionInMenuModal from '@/Hooks/useCheckHasLatestInsitutionInMenuModal';

export default function CheckHasLatestInstitutionInMenu() {
  const isOpen = useSelector((state) => state.checkHasLatestInstitutionModal.isOpen);
  const institutionId = useSelector((state) => state.checkHasLatestInstitutionModal.institutionId);
  const { closeCheckHasLatestInstitutionInMenuModal } = useCheckHasLatestInstitutionInMenuModal();

  if (!isOpen) return null;

  const handleVisitInstitutions = () => {
    Inertia.visit(route('institutions.edit', { institutionId: institutionId }), {
      onFinish: () => {
        closeCheckHasLatestInstitutionInMenuModal();
      },
    });
  };

  return (
    <Modal width={650} height={'10rem'} title={'Nieaktualne normy żywieniowe w jadłospisie'} isOpen={isOpen}>
      <div className={'flex flex-row items-center justify-between gap-x-2'}>
        <ParagraphBold>Zaktualizuj liczbę osób w placówce, aby móc kontynuować</ParagraphBold>
        <PrimaryButton label={'Aktualizuj'} onClick={handleVisitInstitutions} />
      </div>
    </Modal>
  );
}
