import React from 'react';
import Button from './Button';

export interface Props {
  id?: string;
  testId?: string;
  icon?: React.FC;
  label?: string;
  type?: string;
  onClick?: (e: React.MouseEvent) => void;
  width?: number | 'auto' | undefined;
  marginBottom?: number;
  justify?: string;
  zIndex?: number;
  style?: React.CSSProperties;
  disabled?: boolean;
  className?: string;
}

const SmallButton: React.FC<Props> = (props: Props) => {
  return (
    <Button
      {...props}
      option={'small'}
      type={props.type ?? 'button'}
      style={props.style}
      className={props.className}
    />
  );
};

export default SmallButton;
