import { useDispatch } from 'react-redux';
import { closeModal, openModal } from '@/redux/actions/checkHasLatestInstitutionModalAction';

const useCheckHasLatestInstitutionInMenuModal = () => {
  const dispatch = useDispatch();

  const showModal = (institutionId: number) => {
    dispatch(openModal(institutionId));
  };

  const closeCheckHasLatestInstitutionInMenuModal = () => {
    dispatch(closeModal());
  };

  return { showModal, closeCheckHasLatestInstitutionInMenuModal };
};

export default useCheckHasLatestInstitutionInMenuModal;
