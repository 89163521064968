import { CLOSE_MODAL, OPEN_MODAL } from '@/redux/actions/checkHasLatestInstitutionModalAction';

const initialState = {
  isOpen: false,
  institutionId: null,
};

const checkHasLatestInstitutionModalReducer = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, isOpen: true, institutionId: action.payload.institutionId };
    case CLOSE_MODAL:
      return { ...state, isOpen: false, institutionId: null };
    default:
      return state;
  }
};

export default checkHasLatestInstitutionModalReducer;