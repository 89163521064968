import { TutorialStep } from '../types';

export function linkTutorialSteps(steps: TutorialStep[]): TutorialStep[] {
  for (let i = 0; i < steps.length; i++) {
    const currentStep = steps[i];

    if (currentStep.previous === undefined) {
      currentStep.previous = steps[i - 1]?.id || null;
    }
    if (currentStep.next === undefined) {
      currentStep.next = steps[i + 1]?.id || null;
    }
  }
  return steps;
}
