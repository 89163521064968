import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const dishesPage: RawTutorialStep[] = [
  {
    route: '/dishes',
    content: {
      title: 'Wyszukiwarki',
      text: ['Szybkie wyszukiwanie potraw po nazwie oraz względem bazy, przedziału wiekowego i kategorii posiłku.'],
    },
    positioning: {
      align: 'bottom',
      justify: 'center',
    },
    target: {
      selector: '#menus-list-filters',
    },
  },
  {
    route: '/dishes',
    content: {
      title: 'Opcje produktu',
      text: [<span className=" text-primary-500">Kliknij w opcje (3 kropki)</span>],
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },

    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      if (!targetElement) return;
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    canGoNext: false,
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/dishes',
    content: {
      title: 'Podgląd',
      text: ['Przejście do karty potrawy.'],
    },
    target: {
      selector: '#dish-preview-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/dishes',
    content: {
      title: 'Powiel',
      text: [
        'Funkcja "Powiel" służy do szybkiego dodawania do Bazy własnej potraw podobnych do tych, które już istnieją w Bazie Intendent Pro. Powiel podobną potrawę, a w wypełnionym formularzu wprowadź potrzebne zmiany, np. uzupełnij nazwę. W ten sposób szybko poszerzysz Bazę własną.',
      ],
    },
    target: {
      selector: '#duplicate-dish-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/dishes',
    content: {
      title: 'Drukuj',
      text: ['Po kliknięciu w przycisk „Drukuj” wygeneruje się wydruk przepisu wybranej potrawy.'],
    },
    target: {
      selector: '#dish-print-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/dishes',
    content: {
      title: 'Dodaj nową potrawę',
      text: [
        'Dodasz teraz przykładową potrawę do Bazy własnej.',
        <span className=" text-primary-500">Kliknij w przycisk "Dodaj potrawę".</span>,
      ],
    },
    positioning: {
      align: 'center',
      justify: 'left',
    },

    target: {
      selector: '#add-new-dish-button',
      padding: 5,
    },
    onMount: ({ unlockDropdown }) => {
      unlockDropdown();

      return () => null;
    },
    canGoNext: false,
  },
];
