import { createSlice, PayloadAction } from '@reduxjs/toolkit';
interface TutorialState {
  currentStepId: string | null;
  isTutorialActive: boolean;
  isTutorialEnabled: boolean;
}

export interface RouteUrlMapping {
  [key: string]: {
    route: string;
    url: string;
  };
}

const storedState = localStorage.getItem('tutorialState');
const initialState: TutorialState = storedState
  ? JSON.parse(storedState)
  : {
      currentStepId: null,
      isTutorialActive: false,
      routeUrlMappings: {},
      hasCreatedTutorialMenu: false,
      isTutorialEnabled: false,
    };

export const tutorialSlice = createSlice({
  name: 'tutorial',
  initialState,
  reducers: {
    setCurrentStepId: (state, action: PayloadAction<string | null>) => {
      state.currentStepId = action.payload;
    },
    setTutorialActive: (state, action: PayloadAction<boolean>) => {
      state.isTutorialActive = action.payload;
    },
    setTutorialStatus: (state, action: PayloadAction<boolean>) => {
      state.isTutorialEnabled = action.payload;
    },
  },
});

export const { setCurrentStepId, setTutorialActive, setTutorialStatus } = tutorialSlice.actions;

export default tutorialSlice.reducer;
