import { useDispatch } from 'react-redux';
import { openModal, closeModal } from '@/redux/actions/userMustDuplicateModalActions';

const useUserMustDuplicateMenuModal = () => {
  const dispatch = useDispatch();

  const showModal = (menuId: number) => {
    dispatch(openModal(menuId));
  };

  const closeModalUserMustDuplicateMenuModal = () => {
    dispatch(closeModal());
  };

  return { showModal, closeModalUserMustDuplicateMenuModal };
};

export default useUserMustDuplicateMenuModal;
