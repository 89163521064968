import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToNextStep } from '../../utils/subscribeToClick';

export const alternativeInstitutionsPage: RawTutorialStep[] = [
  {
    route: '/institutions',
    id: 'institution-item-of-index-0',
    dropdownId: 'list-dropdown-button-0',
    content: {
      title: 'Opcje placówki',
      text: [<span className="text-primary-500">Kliknij w przycisk „Opcje” (3 kropki).</span>],
    },
    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      if (!targetElement) return;
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    canGoNext: false,
    previous: 'facilities_link',
  },
  {
    dropdownId: 'list-dropdown-button-0',
    route: '/institutions',
    content: {
      title: 'Edycja placówki',
      text: [
        'Przejdziemy teraz do formularza Twojej placówki.',
        <span className="text-primary-500">Kliknij w przycisk „Edytuj”.</span>,
      ],
    },
    target: {
      selector: '#edit-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ lockDropdown }) => {
      lockDropdown();
      return () => null;
    },
    canGoNext: false,
  },
];
