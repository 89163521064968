import clsx from 'clsx';
import React, { CSSProperties } from 'react';

type Props = {
  id?: string;
  children: (number | string | JSX.Element) | (number | string | JSX.Element)[];
  style?: CSSProperties | undefined;
  className?: string;
};

const ParagraphBold = (props: Props) => {
  return <p {...props} className={clsx('font-semibold ', props.className)} />;
};

export default ParagraphBold;
