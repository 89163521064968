import React from 'react';
import { RawTutorialStep } from '../../types';
import { subscribeToDisableOnHover, subscribeToNextStep } from '../../utils/subscribeToClick';

export const singleMenuItem: RawTutorialStep[] = [
  {
    id: 'menu-list-page',
    route: '/menus',
    content: {
      title: 'Lista jadłospisów',
      text: [
        'W tym miejscu znajdziesz swoje jadłospisy. Nowe jadłospisy ustawiają się na górze listy. Pod nazwą odczytasz rodzaj placówki, do której należy jadłospis, ilość dni jadłospisu, kaloryczność oraz data i godzina ostatniej zmiany',
      ],
    },
    target: {
      selector: '#tutorial-menu',
      padding: 5,
    },
    positioning: {
      justify: 'center',
      align: 'top',
    },
  },
  {
    id: 'menu-dropdown-button-button',
    route: '/menus',
    content: {
      title: 'Opcje Jadłospisu',
      text: [<span className="text-primary-500">Kliknij w przycisk „Opcje” (3 kropki).</span>],
    },
    target: {
      selector: '#dropdown-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, nextStep, unlockDropdown }) => {
      if (!targetElement) return;
      const unsubscribe = subscribeToNextStep({
        targetElement,
        nextStep,
      });

      return () => {
        unlockDropdown();
        unsubscribe();
      };
    },
    canGoNext: false,
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Podgląd',
      text: ['Przejście do podglądu całego jadłospisu.'],
    },
    target: {
      selector: '#menu-list-new-preview-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });

      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Edycja',
      text: ['Przejście do edycji jadłospisu.'],
    },
    target: {
      selector: '#menu-list-edit-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Powiel',
      text: [
        'Powielanie jadłospisu to najczęściej wykorzystywana funkcja,',
        'by dostosować stary jadłospis do nowych parametrów.',
        'Powiel stary jadłospis, zmień daty na aktualne i jeśli chcesz, nanieś drobne zmiany.',
        'Nowy jadłospis gotowy!',
      ],
    },
    target: {
      selector: '#menu-list-duplicate-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Przelicz',
      text: [
        'Funkcja „Przelicz” pomoże w dostosowaniu jadłospisu do norm innej placówki.',
        'Program przeliczy gramatury składników wszystkich posiłków, aby dopasować je do nowych norm. ',
        'Przekształci jadłospis przedszkolny na szkolny lub przedszkolny na żłobkowy.',
        'Korzystając z funkcji przelicz, nie musisz układać drugiego,',
        'podobnego jadłospisu od początku. Program przygotuje go za Ciebie!',
      ],
    },
    target: {
      selector: '#menu-list-recalculation-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Drukuj',
      text: ['Przejście do okna wydruków.'],
    },
    target: {
      selector: '#menu-list-print-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Udostępnij',
      text: [
        'Klikając w przycisk "Udostępnij", wygenerujesz link do podglądu jadłospisu. Skopiuj link i wklej go np. na stronę internetową swojej placówki. Osoby niezalogowane (rodzice, opiekunowie) będą mogłi podejrzeć Twój jadłospis w trypie „tylko do podglądu”. ',
      ],
    },
    target: {
      selector: '#menu-details-share-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Usuń',
      text: [
        'Usunięcie jadłospisu. Jeśli niechcący usuniesz jadłospis, nie panikuj! Tak naprawdę zostanie on przeniesiony do kosza na 30 dni. W tym czasie możesz przywrócić go z powrotem na główną listę jadłospisów.',
      ],
    },
    target: {
      selector: '#menu-list-delete-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      const unsubscribe = subscribeToDisableOnHover({ targetElement });
      return unsubscribe;
    },
  },
  {
    dropdownId: 'menu-list-dropdown-0',
    route: '/menus',
    content: {
      title: 'Edycja',
      text: ['Przejście do edycji jadłospisu.', <span className="text-primary-500">Kliknij w przycisk „Edycja”.</span>],
    },
    target: {
      selector: '#menu-list-edit-button',
      padding: 0,
    },
    positioning: {
      justify: 'left',
      align: 'center',
    },
    onMount: ({ targetElement, lockDropdown }) => {
      lockDropdown();

      if (!targetElement) return;

      return () => null;
    },
    canGoNext: false,
  },
];
