import { useDispatch } from 'react-redux';
import { openLoaderModal, closeModal } from '@/redux/actions/loaderModalActions';

const useLoaderModal = () => {
  const dispatch = useDispatch();

  const showLoaderModal = (text?: string) => {
    dispatch(openLoaderModal(text));
  };

  const closeLoaderModal = () => {
    dispatch(closeModal());
  };

  return { showLoaderModal, closeLoaderModal };
};

export default useLoaderModal;
