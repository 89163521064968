import React, {CSSProperties} from "react"
import clsx from "clsx";

type Props = {
    children?: (undefined | number | string | JSX.Element) | (undefined | number | string | JSX.Element)[],
    style?: CSSProperties | undefined;
    className?: string,
}

const Paragraph = (props: Props) => {
    return <p {...props} className={clsx('font-light', props.className)}/>
}

export default Paragraph
