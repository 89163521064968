import React from 'react';

export default function Heading3(props: Props) {
  return (
    <h3
      className={`text-xl font-semibold leading-6 ${props.className}`}
      style={{ fontFamily: 'Open Sans', color: props.color || '#26282C'}}
    >
      {props.text}
      {props.children}
    </h3>
  );
};

type Props = {
  className?: string;
  text?: string;
  children?: string;
  color?: string;
};