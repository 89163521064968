export function subscribeToClick({ targetElement, onClick }: { targetElement: HTMLElement; onClick: () => void }) {
  if (!targetElement) return () => null;

  targetElement.addEventListener('click', onClick);

  return () => {
    targetElement.removeEventListener('click', onClick);
  };
}

export function subscribeToClickWithLoading({
  targetElement,
  startLoading,
  stopLoading,
}: {
  targetElement: HTMLElement | null;
  startLoading: () => void;
  stopLoading: () => void;
}) {
  if (!targetElement) return () => null;

  const handleClick = () => {
    startLoading();
  };

  const unsubscribeClick = subscribeToClick({
    targetElement,
    onClick: handleClick,
  });

  return () => {
    unsubscribeClick();
    stopLoading();
  };
}

export function subscribeToDisableOnHover({ targetElement }: { targetElement: HTMLElement | null }) {
  if (!targetElement) return () => null;

  const button = targetElement as HTMLButtonElement;

  const handleMouseEnter = () => {
    button.disabled = true;
  };

  const handleMouseLeave = () => {
    button.disabled = false;
  };

  targetElement.addEventListener('mouseenter', handleMouseEnter);
  targetElement.addEventListener('mouseleave', handleMouseLeave);

  return () => {
    targetElement.removeEventListener('mouseenter', handleMouseEnter);
    targetElement.removeEventListener('mouseleave', handleMouseLeave);
    button.disabled = false;
  };
}

export function subscribeToNextStep({
  targetElement,
  nextStep,
}: {
  targetElement: HTMLElement | null;
  nextStep: () => void;
}) {
  if (!targetElement) return () => null;
  const handleClick = () => {
    nextStep();
  };
  targetElement.addEventListener('click', handleClick);
  return () => {
    targetElement.removeEventListener('click', handleClick);
  };
}

export const subscribeToModalList = ({
  targetElement,
  nextStep,
  itemSelector = '.infinite-scroll-component div',
  buttonSelector = 'button',
  onButtonClick = (button: HTMLElement) => subscribeToNextStep({ targetElement: button, nextStep }),
}: {
  targetElement: HTMLElement | null;
  nextStep: () => void;
  itemSelector?: string;
  buttonSelector?: string;
  onButtonClick?: (button: HTMLElement) => void;
}) => {
  if (!targetElement) return;

  const cleanupFunctions: (() => void)[] = [];
  const processedItems = new Set();

  const handleContainerClick = (e: MouseEvent) => {
    const clickedElement = e.target as HTMLElement;
    const listItem = clickedElement.closest(itemSelector);

    if (listItem && !processedItems.has(listItem)) {
      setTimeout(nextStep);
    }
  };

  targetElement.addEventListener('click', handleContainerClick);
  cleanupFunctions.push(() => targetElement.removeEventListener('click', handleContainerClick));

  const closeButton = targetElement.querySelector(buttonSelector) as HTMLButtonElement;
  if (closeButton) {
    const closeCleanup = subscribeToClick({
      targetElement: closeButton,
      onClick: () => onButtonClick(closeButton),
    });
    cleanupFunctions.push(closeCleanup);
  }

  return () => {
    cleanupFunctions.forEach((cleanup) => cleanup());
  };
};
